import {ArticlesQueryVariables, useArticlesQuery} from '~/graphql/graphql';

export const useArticles = () => {
  const {result, variables, fetchMore} = useArticlesQuery();

  const internalPageCounter = ref(1);
  const articlesFetchMore = () => {
    fetchMore({
      variables: {
        page: ++internalPageCounter.value,
      },
      updateQuery(previousResult, {fetchMoreResult}) {
        return {
          articles: {
            ...fetchMoreResult?.articles,
            data: [
              ...previousResult?.articles?.data,
              ...fetchMoreResult?.articles?.data,
            ],
          },
        };
      },
    });
  };

  return {
    articles: computed(() => result.value?.articles?.data ?? []),
    articlesUpdateVariables: (data: ArticlesQueryVariables) => variables.value = data,
    articlesFetchMore,
  };
};
